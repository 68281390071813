/* Generated file. Please read https://github.com/jobteaser/next-jobteaser/blob/master/README.md#i18n. */
import * as v from "valibot"

export const i18nLocaleSchema = v.union([
  v.literal("ca"),
  v.literal("cs"),
  v.literal("da"),
  v.literal("de"),
  v.literal("en"),
  v.literal("es"),
  v.literal("fi"),
  v.literal("fr"),
  v.literal("gl"),
  v.literal("it"),
  v.literal("nl"),
  v.literal("no"),
  v.literal("pl"),
  v.literal("pt"),
  v.literal("sv"),
])
