import * as v from "valibot"

import { i18nLocaleSchema } from "@/modules/i18n/schemas"
import {
  COMPANY_ADMIN_ROLES,
  DEFAULT_USER_ROLE,
  JT_ADMIN_ROLES,
  ONBOARDING_STATUSES,
  RECRUITER_ADMIN_ROLES,
  SCHOOL_ADMIN_ROLES,
  STUDENT_ROLES,
} from "@/modules/user/api/types"

export const studentRoleSchema = v.picklist(STUDENT_ROLES)
const defaultUserRoleSchema = v.picklist(DEFAULT_USER_ROLE)
const jtAdminRoleSchema = v.picklist(JT_ADMIN_ROLES)
const schoolAdminRoleSchema = v.picklist(SCHOOL_ADMIN_ROLES)
const companyAdminRoleSchema = v.picklist(COMPANY_ADMIN_ROLES)
const recruiterAdminRoleSchema = v.picklist(RECRUITER_ADMIN_ROLES)

export const currentUserRoleSchema = v.union([
  defaultUserRoleSchema,
  studentRoleSchema,
  jtAdminRoleSchema,
  schoolAdminRoleSchema,
  companyAdminRoleSchema,
  recruiterAdminRoleSchema,
])

export const currentUserCurriculumIdsSchema = v.union([v.array(v.string()), v.null()])

export const onboardingStatusesSchema = v.picklist(ONBOARDING_STATUSES)

export const currentUserAttributesSchema = v.object({
  anonymous: v.boolean(),
  anyCompanyRole: v.boolean(),
  avatarUrl: v.nullable(v.string()),
  careerCenterId: v.nullable(v.string()),
  careerCenterShortName: v.nullable(v.string()),
  careerCenterUuid: v.nullable(v.string()),
  companyPermalink: v.nullable(v.string()),
  companyUuid: v.nullable(v.string()),
  country: v.nullable(v.string()),
  curriculumIds: currentUserCurriculumIdsSchema,
  dailyJobOffersAlert: v.nullable(v.boolean()),
  email: v.nullable(v.string()),
  familyName: v.nullable(v.string()),
  firstName: v.nullable(v.string()),
  givenName: v.nullable(v.string()),
  hasAlumniCurriculum: v.boolean(),
  hasCommunityDiscussions: v.boolean(),
  hasCommunityInsights: v.boolean(),
  hasCommunityModule: v.boolean(),
  hasExclusiveOffers: v.boolean(),
  hasSchoolProfile: v.nullable(v.boolean()),
  isAdmin: v.nullable(v.boolean()),
  isAlumni: v.nullable(v.boolean()),
  isCcSuperAdmin: v.nullable(v.boolean()),
  isHiddenAdmin: v.nullable(v.boolean()),
  isStudent: v.nullable(v.boolean()),
  isWithCompanySpace: v.nullable(v.boolean()),
  jwtToken: v.string(),
  lastName: v.nullable(v.string()),
  lastSignInAt: v.nullable(v.string()),
  locales: v.optional(v.array(i18nLocaleSchema)),
  onboardingStatus: onboardingStatusesSchema,
  recruiter: v.nullable(v.boolean()),
  roleTitle: currentUserRoleSchema,
  schoolId: v.nullable(v.number()),
  showJobRoles: v.nullable(v.boolean()),
  uuid: v.nullable(v.string()),
})

export const currentUserRelationShipsSchema = v.object({
  careerCenter: v.object({
    data: v.object({
      id: v.string(),
      type: v.string(),
    }),
  }),
})

export const currentUserSchema = v.object({
  attributes: currentUserAttributesSchema,
  id: v.string(),
  isLogged: v.boolean(),
  relationships: currentUserRelationShipsSchema,
  type: v.string(),
})

export const currentUserResponseSchema = v.object({
  data: currentUserSchema,
})
