import { createParseError, createRequestError, ensureError } from "@/modules/fetch/errorHandler"

type GetResultFromResponseOptions<T> = {
  onHttpRedirect?: () => T
  transformResult?: (result: unknown) => T
  useAPIErrorResponse?: boolean
}

export const handleResponseNotOk = async (response: Response, useAPIErrorResponse: boolean = false): Promise<void> => {
  if (response.ok) return

  const body = useAPIErrorResponse ? await response.clone().json() : undefined

  throw createRequestError(response, body)
}

export const getResultFromResponse =
  <T>(options?: GetResultFromResponseOptions<T>) =>
  async (response: Response): Promise<T> => {
    const { useAPIErrorResponse = false } = options ?? {}

    await handleResponseNotOk(response, useAPIErrorResponse)

    if (response.status === 300 && options?.onHttpRedirect) {
      return options.onHttpRedirect()
    }

    const responseText = await response.clone().text()

    try {
      if (options?.transformResult) {
        return options.transformResult(await response.json())
      }

      return (await response.json()) as T
    } catch (error) {
      throw createParseError(responseText, ensureError(error))
    }
  }
