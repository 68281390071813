export const ACTIVE_FLAGS_HEADER_NAME = "active-ff"
export const UNLEASH_CONTEXT_HEADER_NAME = "unleash-context"

// List of the page paths supporting UI Shift until all of them are compatible
// See routing-config.json
export const PAGE_PATHNAMES_WITH_UI_SHIFT = [
  "companies",
  "events",
  "job-offers",
  "job-search",
  "debug",
  "career-service",
]

export const BO_PAGE_PATHNAMES_WITH_UI_SHIFT = ["career-explorer"]
