type FaviconPath = `/favicon${"-local" | "-staging" | ""}.ico`

const getFaviconPath = (): FaviconPath => {
  const env = process.env.NEXT_PUBLIC_ENV || "local"

  let faviconPath: FaviconPath
  switch (env) {
    case "local": {
      faviconPath = "/favicon-local.ico"
      break
    }
    case "feature":
    case "staging": {
      faviconPath = "/favicon-staging.ico"
      break
    }
    default: {
      faviconPath = "/favicon.ico"
    }
  }

  return faviconPath
}

export { getFaviconPath, type FaviconPath }
